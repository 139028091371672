<template>
  <div>
    <div>
      <div class="_BoxHeader">
        <span>岗位与职能维护</span>
      </div>
      <div class="PageContain">
        <div class="_SelectHeader">
          <span class="_Title">信息列表</span>
          <div class="_Select">
            <span>公司：</span>
            <el-select
              v-model="value1"
              clearable
              placeholder="请选择"
              @change="compyInp"
            >
              <el-option
                v-for="item in compyList"
                :key="item.id"
                :label="item.companyName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="_Select">
            <span>部门：</span>
            <el-select
              v-model="value2"
              clearable
              placeholder="请选择"
              @change="deptmenInp"
            >
              <el-option
                v-for="item in option2"
                :key="item.id"
                :label="item.deptmentName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="_Select">
            <span>岗位：</span>
            <el-select
              v-model="value3"
              @clear="getFunList"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in option3"
                :key="item.id"
                :label="item.postName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <el-button type="success" icon="el-icon-search" @click="searchList"
            >搜索</el-button
          >
          <el-button type="primary" @click="addJobFun">添加职能</el-button>
        </div>
        <div class="_SelectHeader" style="border-top: 1px solid #e9e7e8">
          <span class="_Title">列表信息</span>
        </div>
        <div class="_Table">
          <el-table
            :data="
              JobFunList.slice(
                (currentPage - 1) * pageSize,
                currentPage * pageSize
              )
            "
            style="width: 100%"
            height="100%"
            :header-cell-style="{ background: '#E8EBF4', color: '#5C5C5C' }"
            border
          >
            <el-table-column type="selection" width="80"></el-table-column>
            <el-table-column prop="postName" label="岗位名称" width="180">
            </el-table-column>
            <el-table-column prop="functionName" label="职能名称">
            </el-table-column>
            <el-table-column prop="saveTime" label="保存日期">
            </el-table-column>
            <!-- <el-table-column prop="address" label="操作" width="100">
              <template slot-scope="scope">
                <i class="el-icon-delete" @click="deleteJobFun(scope.row)"
                  >删除</i
                >
              </template>
            </el-table-column> -->
          </el-table>
        </div>
        <div class="_Pagination">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[20, 30, 50, 100]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="JobFunList.length"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <Dialog
      title="添加岗位职能"
      :showTowText="false"
      width="25%"
      top="25vh"
      :showDialog="showDialog"
      class="dialog"
      @handleReqData="handleReqData"
      @handleClose="handleClose"
    >
      <div class="_Select">
        <span>岗位名称</span>
        <el-select v-model="value3" placeholder="请选择">
          <el-option
            v-for="item in option3"
            :key="item.id"
            :label="item.postName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="_Select">
        <span>职能名称</span>
        <el-select
          v-model="value4"
          placeholder="请选择"
          filterable
          multiple
          @change="handleSelect"
        >
          <el-option
            v-for="item in functionList"
            :key="item.id"
            :label="item.functionName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
    </Dialog>
  </div>
</template>

<script>
import { get, put } from '../../api/http'
import Dialog from '../../components/dialog/Dialog.vue'
export default {
  components: { Dialog },
  data() {
    return {
      isSerach: false,
      showDialog: false,
      JobFunList: [],
      // 下拉框
      compyList: this.$store.state.companyList,
      deptList: [],
      jobList: [],
      functionList: [],
      option2: [],
      option3: [],
      Company1: '',
      value1: '',
      value2: '',
      value3: '',
      value4: '',
      // 分页
      currentPage: 1,
      pageSize: 20,
    }
  },
  mounted() {
    this.getDeptAll()
    this.getFunList()
    this.getJobFunAll()
  },
  methods: {
    renderTime(date) {
      var time = new Date(date).toJSON()
      return new Date(+new Date(time) + 8 * 3600 * 1000)
        .toISOString()
        .replace(/T/g, ' ')
        .replace(/\.[\d]{3}Z/, '')
    },
    handleSizeChange(val) {
      this.currentPage = 1
      this.pageSize = val
    },
    handleCurrentChange(val) {
      this.currentPage = val
    },
    handleClose() {
      this.showDialog = false
    },
    handleReqData() {
      if (this.value4.length == 0) {
        this.$message({
          type: 'warning',
          message: '请选择',
        })
        return
      }
      this.showDialog = false
      this.addJobFunItem()
    },
    getDeptAll() {
      get('/api/Department/GetAllDept').then((resp) => {
        if (resp.code == 200) {
          this.deptList = resp.data
        }
      })
    },
    getFunList() {
      get('/api/Function/GetList').then((resp) => {
        if (resp.code == 200) {
          this.functionList = resp.data
        }
      })
    },
    // 筛选
    compyInp(e) {
      this.value2 = ''
      this.value3 = ''
      if (e) {
        this.Company1 = e
        get('/api/Department/GetNoTree?CompId=' + e).then((res) => {
          this.option2 = res.data
        })
      } else {
        this.isSerach = false
        this.getJobFunAll()
      }
    },
    deptmenInp(e) {
      this.deptmentName1 = e
      this.value3 = ''
      get('/api/Post?CompId=' + this.Company1 + '&DeptId=' + e).then((res) => {
        console.log(res)
        this.option3 = res.data
      })
    },

    getJobListAll() {
      this.isSerach = false
      // this.jobList = ''
      // this.value3 = ''
      // get('/api/Post?CompId=' + this.value1 + '&DeptId=' + this.value2).then(
      //   (resp) => {
      //     if (resp.code == 200) {
      //       this.jobList = resp.data
      //       this.value3 = resp.data[0].id
      //     }
      //   }
      // )
      this.searchList()
    },
    // 获取岗位职能信息
    searchList() {
      this.isSerach = true
      this.getJobFunAll()
    },
    getJobFunAll() {
      var url1 = '/api/PostAndFunction/GetPostAndFunction?PostId=' + this.value3
      var url2 = '/api/PostAndFunction/GetList'
      if (this.isSerach && !this.value3) {
        this.$message({
          type: 'warning',
          message: '未选择岗位',
        })
        return
      }
      get(this.isSerach ? url1 : url2).then((resp) => {
        if (resp.code == 200) {
          if (this.showDialog) {
            resp.data.map((item) => {
              this.value4.push(item.functionId)
            })
          } else {
            resp.data.map((item) => {
              item.saveTime = this.renderTime(item.saveTime)
            })
            this.JobFunList = resp.data
          }
        }
      })
    },

    // 添加职能
    handleSelect(val) {
      console.log(val)
      this.value4 = val
    },
    addJobFun() {
      if (this.value3 == '' || this.value3 == undefined) {
        this.$message({
          type: 'warning',
          message: '岗位暂无信息，请先选择公司部门信息',
        })
        return
      }
      this.getJobListAll()
      this.showDialog = true
      this.value4 = []
    },
    addJobFunItem() {
      var reqList = []
      this.value4.map((item) => {
        reqList.push({
          Id: 0,
          FunctionId: item,
          PostId: this.value3,
          SaveTime: new Date().toISOString(),
          SaveUserId: JSON.parse(sessionStorage.getItem('userInfo')).Id + '',
        })
      })
      put('/api/PostAndFunction?PostId=' + this.value3, {
        data: JSON.stringify(reqList),
      })
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              type: 'success',
              message: '添加成功',
            })
            this.searchList()
          } else {
            this.$message.error(resp.message)
          }
        })
        .catch(() => {
          this.$message.error('添加失败')
        })
    },
    deleteJobFun(row) {
      this.$confirm('您确认要删除' + row.functionName + '这个职能？')
        .then(() => {})
        .catch(() => {})
    },
  },
}
</script>

<style lang="less" scoped>
.dialog {
  ._Select {
    width: 90% !important;
    margin-top: 10px;
    span {
      padding-right: 10px;
    }
    .el-select {
      overflow: hidden !important;
    }
    /deep/ .el-select__tags {
      flex-wrap: nowrap !important;
      overflow: hidden;
    }
  }
}
.el-icon-delete {
  color: red;
}
._Table{
  height:calc(100% - 200px);
}
</style>
